<template>
  <div>
    <h2 class="text-center py-5 my-5 display-4 font-weight-bolder text-muted">Coming Soon</h2>
  </div>
</template>

<script>
export default {
  name: "OrderFormList"
};
</script>

<style>
</style>
